<template>
  <primary-page />
</template>

<script>
  import PrimaryPage from '../components/PrimaryPage.vue'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',

    components: {
      PrimaryPage,
    },
  }
</script>
