import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg' || 'far' || 'far-icon' || 'far2',
    },
    theme: {
        themes: {
            light: {
                primary: '#0231a5',
                secondary: '#191919',
                success: '#c4b820',
                info: '#248e23',
                error: '#a80c00'
            }
        }
    }
});
