<template>
  <v-app>
      <v-app-bar
          app
          color="grey lighten-4"
          dark
          elevate-on-scroll
          elevation="5"
      >
        <v-container class="d-flex align-center justify-center">
          <div class="d-flex align-center">
            <v-img
                alt="Messa Logo"
                class="shrink mr-2"
                contain
                src="./assets/messa-jpg-cropped.jpg"
                transition="scale-transition"
                width="140"
            />
          </div>

          <v-spacer></v-spacer>

          <v-btn
              href="https://www.facebook.com/profile.php?id=61564169956901&mibextid=LQQJ4d"
              target="_blank"
              fab
              text
              small
              class="hover-1 mr-1">
            <v-icon>fa-facebook</v-icon>
          </v-btn>
          <v-btn
              href="https://www.instagram.com/messa_architectural_studio"
              target="_blank"
              fab
              text
              small
              class="hover-1 mr-1">
            <v-icon>fa-instagram</v-icon>
          </v-btn>
          <v-btn
              href="https://www.behance.net/messastudio"
              target="_blank"
              fab
              text
              small
              class="hover-1 mr-1">
            <v-icon>fa-behance</v-icon>
          </v-btn>
          <v-btn
              href="https://api.whatsapp.com/send/?phone=37444620220"
              target="_blank"
              fab
              text
              small
              class="hover-1 mr-1">
            <v-icon>fa-whatsapp</v-icon>
          </v-btn>
        </v-container>

      </v-app-bar>

    <v-main class="grey lighten-4">
      <v-btn
          href="tel:+37444620220"
          color="primary"
          large
          fab
          dark
          class="phone-absolute hover-1">
        <v-icon color="white">mdi-phone-in-talk</v-icon>
      </v-btn>
      <div class="container-img">
        <v-img
            dark
            cover
            class="img-overlay img-height-calc"
            src="./assets/img/8.jpg"
            width="100%"
        >
        </v-img>

        <div class="centered">
          <v-container class="h-100">
            <v-row>
              <v-col
                  tag="h1"
                  cols="12" sm="6"
                  class="font-weight-bold font-size-xl-65 font-size-lg-45 text-sm-left pl-md-0 pl-7"
              >
                Welcome to Messa!
              </v-col>
              <v-col
                  class="font-size-xl-35 font-size-lg-25 font-size-md-20 text-sm-left pr-md-0 pr-7"
                  cols="12" sm="6"
              >
                We're Messa, a passionate architectural and interior design studio dedicated to transforming spaces into unique works of art.</v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import 'scss/main';

.img-height-calc {
  max-height: calc(100vh - 64px);
}

.img-overlay {
  filter: brightness(40%)
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .img-overlay {
  display: block;
  width: 100%;
  height: auto;
}

.container-img {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.phone-absolute {
  position: fixed;
  top: 87%;
  right: 25px;
  z-index: 100;
}
</style>
