<template>
  <v-container>
    <v-row class="text-center">

      <v-col class="text-sm-left">
        <v-row class="py-lg-16 py-md-10 py-5">
          <v-col tag="h2" cols="12" sm="6" class="font-weight-bold">Our Services</v-col>
          <v-col cols="12" sm="6">
            <p><span class="font-weight-bold">Architectural Design:</span><br/>From concept development to construction documentation, we specialize in designing buildings that stand the test of time.</p>
            <p><span class="font-weight-bold">Interior Design:</span><br/>We curate spaces that harmonize beauty with functionality, tailoring each design to reflect our clients' unique tastes and needs.</p>
            <p class="mb-0"><span class="font-weight-bold">Project Management:</span><br/>With a focus on efficiency and quality, we oversee every aspect of the project to ensure it's completed on time and within budget.</p>
          </v-col>
        </v-row>
        <v-row class="justify-center">
        <v-col md="3" sm="6" cols="12">
          <v-img cover src="../assets/img/1.jpg"/>
        </v-col>
        <v-col md="3" sm="6" cols="12">
          <v-img cover src="../assets/img/3.jpg"/>
        </v-col>
        <v-col md="3" sm="6" cols="12">
          <v-img cover src="../assets/img/2.jpg"/>
        </v-col>
        <v-col md="3" sm="6" cols="12">
          <v-img cover src="../assets/img/4.jpg"/>
        </v-col>
      </v-row>
        <v-row class="py-lg-16 py-md-10 py-5">
          <v-col tag="h2" cols="12" sm="6" class="font-weight-bold">Collaborate With Us</v-col>
          <v-col cols="12" sm="6">
            At Messa, collaboration is at the heart of everything we do. By partnering closely with our clients, we turn visions into reality, creating spaces that inspire and endure.
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col md="3" sm="6" cols="12">
            <v-img cover src="../assets/img/6.jpg"/>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <v-img cover src="../assets/img/7.jpg"/>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <v-img cover src="../assets/img/8.jpg"/>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <v-img cover src="../assets/img/9.jpg"/>
          </v-col>
        </v-row>
        <v-row class="py-lg-16 py-md-10 py-5">
          <v-col tag="h2" cols="12" sm="6" class="font-weight-bold">Get in Touch</v-col>
          <v-col cols="12" sm="6">
            Contact us today to schedule a consultation with Mesrop Sahakyan and our team. Let's bring your vision to life with the expertise and passion that define Messa Architectural Studio.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col tag="a" href="tel:+37444620220" cols="12" color="primary" class="d-flex justify-center text-decoration-none pb-0">
                  <v-icon color="primary">fa-phone</v-icon>
                <div class="d-flex ml-3">+37444620220</div>
              </v-col>
              <v-col tag="a" href="mailto: info.messastudio@gmail.com" cols="12" color="primary" class="d-flex justify-center text-decoration-none">
                <v-icon color="primary">fa-envelope</v-icon>
                <div class="d-flex ml-3">info.messastudio@gmail.com</div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center pt-0">
                <v-btn
                    href="https://www.facebook.com/profile.php?id=61564169956901&mibextid=LQQJ4d"
                    target="_blank"
                    fab
                    text
                    small
                    class="hover-1">
                  <v-icon>fa-facebook</v-icon>
                </v-btn>
                <v-btn
                    href="https://www.instagram.com/messa_architectural_studio"
                    target="_blank"
                    fab
                    text
                    small
                    class="hover-1">
                  <v-icon>fa-instagram</v-icon>
                </v-btn>
                <v-btn
                    href="https://www.behance.net/messastudio"
                    target="_blank"
                    fab
                    text
                    small
                    class="hover-1">
                  <v-icon>fa-behance</v-icon>
                </v-btn>
                <v-btn
                    href="https://api.whatsapp.com/send/?phone=37444620220"
                    target="_blank"
                    fab
                    text
                    small
                    class="hover-1">
                  <v-icon>fa-whatsapp</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center font-size-12 pt-6">© 2024 Messa Architectural Studio | All rights reserved</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PrimaryPage',

    data: () => ({
    }),
  }
</script>
